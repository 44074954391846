import * as React from "react";
import { Link } from "gatsby";

import "../styles/extraPageStyles.css";

const pageStyle = {
  color: "black",
  backgroundColor: "white",
  height: "100vh",
  padding: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center"
};

const ImprintPage = () => {
  return (
    <main style={pageStyle}>
      <div style={{ width: "80%", display: "flex", alignContent: "left", }}>
        <h1 style={{ flex: 3, }}>Impressum</h1>
        <Link style={{ height: "100%", color: "black", textAlign: "center", justifyContent: "center" }} to="/">
          Zurück zu Home
        </Link>
      </div>
      <div style={{ width: "80%" }}>
        <div>
          <h3>Anschrift</h3>
          <p>
            Hoxhaj Bauunternehmen GmbH<br />
            Am Weidensatz 24
            <br />
            76756 Bellheim
            <br />
            Tel.: (07272) 95 59 21 0 <br />
            E-Mail: info@hoxhaj-gmbh.de <br />
            Internet: www.hoxhaj-gmbh.de
          </p>
        </div>
        <div>
          UST-ID NR: DE321671539
          <br />
          Firmen-Steuernummer 41/650/19453
          <br />
          Finanzamt Speyer Germersheim
        </div>
        <div>
          Geschäftsführung / Inhaltlich Verantwortlich § 55 Abs. 2 RStV: Urim
          Hoxhaj
          <br />
          Handelsregister .NR. HRB 32454 Amtsgericht Landau
          <br />
          Es gelten unsere Allgemeinen Geschäftsbedingungen. <br /><br />
          <h3>Haftungshinweis</h3>
          <p>
            Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine
            Haftung für die Inhalte externer Links. Für den Inhalt der
            verlinkten Seiten sind ausschließlich deren Betreiber
            verantwortlich.
          </p>
        </div>
      </div>
    </main>
  );
};

export default ImprintPage;
